<template>
    <div>
        <div class="pl-3 pr-2">
            <Breadcrumb :breadcrumb="breadCrumbItems"/>
        </div>
        <div class="container mt-3">
            <div class="introduction-edit-sp">
                <div class="row">
                    <div class="col-12 text-center pb-2">
                        <h3 class="title">{{$t("mypage.introduction-edit")}}</h3>
                    </div>
                </div>
                <div class="container">
                    <FormView @submit="updateUserIntroduction">
                        <div class="row contain-box">
                            <div class="col-lg-12">
                                <div>
                                    <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                                        {{msg}}
                                    </div>
                                </div>
                                <div class="group-frm mb-3">
                                    <div class="d-flex justify-content-center">
                                        <div class="col-12 profile p-0">
                                            <UploadBaseImage
                                                :label="$t('mypage.form.profile_image')"
                                                subLabel="(推奨画像サイズ:240x240px)"
                                                name="profile_image"
                                                :url="preview.profile_image"
                                                @change="(e) => onFileChange(e, 'profile_image')"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="group-frm mb-3">
                                    <Input
                                        :label="$t('mypage.form.nickname.name')"
                                        name="nickname"
                                        rules="max:100"
                                        :placeholder="$t('mypage.form.nickname.placeholder')"
                                        v-model="data.nickname"
                                        required
                                    />
                                </div>
                                <div class="group-frm mb-3">
                                <TextArea
                                    :label="$t('mypage.form.introduction.introduction')"
                                    name="introduction"
                                    :placeholder="$t('mypage.form.introduction.placeholder')"
                                    v-model="data.introduction"
                                    rows="10"
                                />
                                </div>
                                <div class="group-frm mb-3" v-if="data.categories.length > 0">
                                    <FormCategory :items="data.categories" />
                                </div>
                                <div class="group-frm text-center mt-3">
                                    <button type="submit" class="btn btn-success" :disabled="isSaving">{{isSaving ? $t("mypage.form.updating_button") : $t("mypage.form.update_button")}}</button>
                                </div>
                            </div>
                        </div>
                    </FormView>
                </div>
            </div>
        </div>
        <MetaTags 
            title="自己紹介編集｜ResMom相談online"
            description="リセマム相談オンラインの自己紹介編集画面です。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormCategory from "./components/form-category.vue";
export default {
    components: {
        FormCategory
    },
    data() {
        return {
            preview: {
                profile_image: "",
            },
            data: {
                member_id: "",
                nickname: "",
                introduction: "",
                profile_image: "",
                site_id: "",
                member_name: "",
                member_email: "",
                categories: [],
            },
            isSaving: false,
            msgType: "danger",
            msg: "",
            userId: "",
            breadCrumbItems: [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: "/my-page"
                },
                {
                    title: this.$t("menu.breadcrumb.introduction_edit"),
                    link: "#"
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            member: "getMember"
        }),
    },
    async created() {
        if (!this.member) {
            await this.getMMSMember();
        }
        await this.getUser();
    },
    methods: {
        ...mapActions({
            updateIntroduction: "user/updateIntroduction",
            fetchUserInformation: "user/fetchUserInformation",
            getMMSMember: "getMMSMember",
        }),
        updateUserIntroduction() {
            if (this.isSaving)
                return;
            this.isSaving = true;
            let data = {
                ...this.data,
                'member_id': this.member.id,
                'member_name': this.member.name,
                'member_email': this.member.email,
                'site_id': this.member.site_id
            };
            this.updateIntroduction({
                id: this.member.id,
                data: data,
                cb: (response) => {
                    if (response.data &&
                        response.meta &&
                        response.meta.code == 200) {
                        this.msg = this.$t("mypage.message.introduction.success");
                        this.msgType = "success";
                        window.location.reload();
                    }
                    else {
                        if (response.meta && response.meta.message) {
                            this.msg = response.meta.message;
                        }
                        else {
                            this.msg = this.$t("mypage.message.introduction.failed");
                        }
                        this.msgType = "danger";
                    }
                    this.isSaving = false;
                }
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        onFileChange({ url, path }, name) {
            this.preview[name] = url;
            this.data[name] = path;
        },
        async getUser() {
            await this.fetchUserInformation({
                id: this.member.id,
                cb: (response) => {
                    let user = response.data;
                    if (user) {
                        this.data = {
                            ...this.data,
                            'nickname': user.nickname,
                            'introduction': user.introduction,
                            'profile_image': null,
                            categories: user.categories,
                        };
                        this.preview.profile_image = user && user.profile_image
                            ? user.profile_image : '';
                    }
                }
            });
        },
    },
}
</script>